
/*.LibraryItem {
  border:0.15em;
  border-radius:0.5em;
  border-style:ridge;
  padding-bottom:0.1em;
  border-color:darkgrey;
}*/

.LibraryItem .title {
  flex-grow:1;

}

