body {
  height:100vh;
  width:100vw;
}

#root {
  height:100%;
}

@media only screen and (min-width: 600px) and (min-height:600px) {
  #root {
    height:100%;
    width:600px;
    margin-left:auto;
    margin-right:auto;
  }  
}

.AppLayout {
  height:100%;
}

.AppContent {
  overflow-y:auto;
}

.AppFooter {
  margin-top:auto;
}
